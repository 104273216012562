<template>
<div class="home">

  <Features
    :hero="true"
    img="img/photo-points-map-banner.jpg">
    <h1>
      Photo Point Report Generator
      <br/><small>by Tall Oaks Environmental</small>
    </h1>

    <p>
      A new, easy to use tool that allows you to quickly generate PDF photo point reports without leaving your browser,
      saving you time and stress. Try it today for free.
    </p>

    <Button
      type="login"
      @click="$router.push('/upload')">
      Try it now
    </Button>

  </Features>

  <div class="sections-container">

    <!-- <Features -->
    <!--   :heror="true"> -->
    <!--   <ul> -->
    <!--     <li><strong>Quick and Easy</strong> -  Drag and drop your images, and they are automatically grouped into photo points using GPS data</li> -->
    <!--     <li><strong>Customizable</strong> - Easily add your own labels, titles, and notes</li> -->
    <!--     <li><strong>Self Contained</strong> -  The report is generated in your browser. Your images never leave your computer</li> -->
    <!--     <li> -->
    <!--       <strong> -->
    <!--         Export -->
    <!--       </strong> - Your work is saved in a single zip file containing your final PDF report and a copy of your images automatically renamed and organized -->
    <!--     </li> -->
    <!--   </ul> -->
    <!-- </Features> -->

    <Features
      :heror="true">
        <h2>Quick and Easy</h2>
        Drag and drop your images, and they are automatically grouped into photo points using their GPS data
        <h2>Customizable</h2>
        Easily add your own labels, titles, and notes
        <h2>Self Contained</h2>
        The report is generated in your browser. Your images never leave your computer
        <h2>Export</h2>
        Your work is saved in a single zip file containing your final PDF report, a copy of your images and a csv file with coordinates automatically renamed and organized
    </Features>

    <!-- <Boxes -->
    <!--   :list="intendedUsers"/> -->

    <Features
      img="https://picsum.photos/id/252/400">
      <h2>Christian Parker</h2>
      <h3>Founder of Tall Oaks Environmental</h3>
      <p>Christian is a talented researcher and consultant with experience across the academic, public and private sectors in statistical analysis, advanced database design, and full stack programming. He specializes in the development and evaluation of environmental predictive models and uncertainty. Through teaching, various ecological research labs, and consulting, Christian has over 20 years of experience building databases and online tools to support data collection and analysis. Christian is passionate about using these skills to make data accessible for his clients to help solve complex environmental problems.</p>
    </Features>

    <Features
      img="img/oaks.jpg"
      :reverse="true">
      <h2>Tall Oaks Environmental</h2>
        <p>Tall Oaks is a new kind of environmental consulting firm. We use the newest computer technologies to create innovative, dynamic products for our clients that streamline environmental projects and provide a superior level of data management. We create environmental monitoring programs with a heavy emphasis on statistics so that findings are meaningful and significant. Our data analysis services help clients decode their data. Tall Oaks believes environmental research is only as good as its communication. Therefore we create plans, reports, and work plans that use understandable and written in human language. We create beautiful data visualizations that tell a story.
        </p>
    </Features>

    </div>

    <Features
      :hero="true"
      background="#386b1280">
      <h2>
        Are you a Land Trust with extensive data management needs?
      </h2>
      <p>
       Tall Oaks Environmental proudly offers the Ouzal Land Management Application, a modern, web based solution for both land stewardship and acquisition, designed in partnership with Columbia Land Trust and used by land trusts across the US.
      </p>

      <a
        href="//www.ouzel.land">
        Learn what Ozuel can do for you
      </a>

    </Features>

    <div class="footer">
      <AboutUs/>
      <ContactUsForm/>
    </div>

</div>
</template>
<script>
import Features from '@/components/Features';
//import Boxes from '@/components/Boxes';
import Button from '@acorns-ui/Inputs/Button';
import ContactUsForm from './ContactUsForm';
import AboutUs from './AboutUs';
import { mapState } from 'vuex';

export default {
  name: 'Home',
  props: {},
  data() {
    return {
      intendedUsers: [
        { label: "Land Trusts", img: "https://picsum.photos/200" },
        { label: "Academic Researchers", img: "https://picsum.photos/200" },
        { label: "Government Agencies", img: "https://picsum.photos/200" },
      ]
    };
  },
  components: {
    Features,
    Button,
    ContactUsForm,
    AboutUs,
   // Boxes,
  },
  created() {},
  watch: {},
  computed: {
    ...mapState('user', [
      'info',
      'loggedIn',
      'checked',
    ]),
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.home  {
  width: 100%;
  display: flex;
  flex-direction: column;
  .sections-container {
    width: 800px;
    margin: 0 auto;
  }

  h1 {
    font-size: 3.5rem;
    line-height: 3.5rem;
    margin-block-start: 0;
    margin-block-end: 0;
    small {
      font-size: 1.5rem;
    }
  }

  .pricing-section {
    display: flex;
    flex-direction: row;
    .card {
      @include shadow;
      margin: 1rem;
      width: calc(33% - 2rem);
    }
  }

  .footer {
    min-height: 20rem;
    //background-color: #3b464c;
    background-color: #363636;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>
