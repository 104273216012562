<template>
<div class="about-us">
  Tall Oaks Environmental Research

  <a href="mailto:chris@talloaks.io">chris@talloaks.io</a>

  <Icon>
    linkedin
  </Icon>


</div>
</template>
<script>
import Icon from '@acorns-ui/Icons/FontIcon';

export default {
  name: 'AboutUs',
  props: {},
  data() {
    return {};
  },
  components: {
    Icon,
  },
  created() {},
  watch: {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.about-us  {
  padding: 2rem;
  color: $white;
}
</style>
