<template>
<div class="contact-us-form">

  <template v-if="success">
    Thank you! We will get back to you as soon as we can
  </template>
  <template v-else>
    <div class="inputs-container">
      <div class="input-container">
        <input
          type="email"
          placeholder="you@company.com"
          v-model="record.email"
          :data-lpignore="true"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          @keyup.enter.stop="updated">
      </div>

      <div class="input-container fill">
        <textarea
          class="fill"
          ref="textarea"
          v-model="record.notes"/>
      </div>
    </div>

    <div class="buttons">
      <Button
        @click="send"
        :disabled="!ready">
      Send
    </Button>
  </div>
  </template>

</div>
</template>
<script>
  import Button from '@acorns-ui/Inputs/Button';
import { post } from '@/api';

export default {
  name: 'ContactUsForm',
  props: {
    subject: {
      type: String,
    }
  },
  data() {
    return {
      success: false,
      error: null,
      record: {
        email: null,
        notes: null,
      }
    };
  },
  components: {
    Button,
  },
  created() {
  },
  watch: {
  },
  computed: {
    validEmail() {
      var re = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/);
      return re.test(this.record.email);
    },
    ready() {
      return !!this.validEmail
        && !!this.record.notes
    },
  },
  methods: {
    send() {
      post('app/contactus', this.record)
        .then( () => {
          this.success = true;
        })
        .catch( err => {
          this.error = err;
        })
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.contact-us-form  {
  padding: 1rem;
  width: 25rem;
  display: flex;
  flex-direction: column;
  .inputs-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .input-container {
      @include input-style;
      margin-bottom: 0.5rem;
      display: flex;
      flex-direction: column;

      input {
        min-height: 2.5rem;
        padding: 0.5rem;
        flex: 1;
        //width: 100%;
      }

      textarea {
        border: 0;
        padding: 0.5rem;
        width: calc(100% - 1rem);
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
</style>
