<template>
<div
  class="features"
  :class="{ reverse, hero }"
  :style="style"
  >
  <div class="outer-container">
    <div class="content-container">
      <slot/>
    </div>
    <div
      v-if="img && !hero"
      class="image-container">
      <img
        :src="img"/>
    </div>
  </div>
</div>

</template>
<script>

export default {
  name: 'Features',
  props: {
    reverse: {
      type: Boolean,
      default: false
    },
    hero: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
    //  default: 'https://picsum.photos/200'
    },
    background: {
      type: String,
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  watch: {},
  computed: {
    style() {
      if(this.hero && this.img) {
        return {
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${this.img}")`,
        };
      } else if(this.background){
        return {
          backgroundColor: this.background,
        };
      } else {
        return {};
      }
    },
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables';
.features {
  width: 100%;

  .outer-container {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
    .content-container {
      flex: 1;
      margin-right: 1rem;
      margin-left: 0;
    }
  }

  &.hero {
    //width: calc(100% - 40rem);
    background-color: rgba(0, 0, 0, 0.5);
    color: $white;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    & > .outer-container {
      margin: 0 auto;
      width: 50rem;
      min-height: 25rem;
    }
  }

  &.reverse {
    & > .outer-container {
      flex-direction: row-reverse;
      .content-container {
        margin-right: 0;
        margin-left: 01rem;
      }
    }
  }

}
</style>
